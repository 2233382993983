import React from "react"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { ToastContainer } from "react-toastify"
import Base from "./components/Layout/Base"
import BasePage from "./components/Layout/BasePage"

import Login from "./components/Auth/Login"
import PasswordReset from "./components/Auth/PasswordReset"
import DashboardFinancial from "./components/Dashboards/Financial/Financial"
import DashboardWallet from "./components/Dashboards/Wallet/Wallet"
import DashboardMetrics from "./components/Dashboards/Metrics/Metrics"
import DashboardSupport from "./components/Dashboards/Support/Support"
import DashboardPartners from "./components/Dashboards/Partners/Partners"
import DashboardTreasury from "./components/Dashboards/Treasury/Treasury"
import DashboardCompliance from "./components/Dashboards/Compliance/Compliance"
import Partners from "./components/Partners/Partners"
import BuyOrders from "./components/Orders/Buy/BuyOrders"
import BuyOrder from "./components/Orders/Buy/BuyOrder"
import SellOrders from "./components/Orders/Sell/SellOrders"
import SellOrder from "./components/Orders/Sell/SellOrder"
import PayoutList from "./components/Orders/Payouts/PayoutList"
import OrderLimits from "./components/Orders/OrderLimits"
import Customers from "./components/Customers/Customers"
import Customer from "./components/Customers/Customer"
import Statistics from "./components/Dashboards/Statistics/Statistics"
import Accounting from "./components/Dashboards/Accounting/Accounting"
import PaymentNotifications from "./components/Transactions/PaymentNotifications"
import MatchUnmatched from "./components/Transactions/MatchUnmatched"
import Partner from "./components/Partners/Partner"
import CommentSearch from "./components/Comment/CommentSearch"
import Whistleblower from "./components/Whistleblower/Whistleblower"
import Mobile from "./components/Dashboards/Mobile/Mobile"
import CustomerReview from "./components/Review/CustomerReview"
import Demographics from "./components/Dashboards/Demographics/Demographics"
import MobileAppDashboard from "./components/Dashboards/MobileApp/MobileAppDashboard"
import AdminUsers from "./components/Admin/Users"
import AdminPermissions from "./components/Admin/Permissions"
import AddUser from "./components/Admin/AddUser"
import User from "./components/Admin/User"
import FeatureFlag from "./components/Developer/FeatureFlag"
import CustomerTakeover from "./components/Developer/CustomerTakeover"
import Welcome from "./components/Welcome/Welcome"
import BusinessDashboard from "./components/Dashboards/Business/BusinessDashboard"
import Tools from "./components/Tools/Tools"
import TransactionList from "./components/Wallet/TransactionList"
import SwapTransactionList from "./components/Wallet/TransactionListSwap"
import TransactionDetail from "./components/Wallet/TransactionDetail"
import TransactionDetailSwap from "./components/Wallet/TransactionDetail/TransactionDetailSwap"
import CoinFunctions from "./components/Operations/CoinFunctions"
import StorageUtils from "./components/Common/StorageUtils"

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/"
  const timeout = { enter: 500, exit: 500 }
  const user = StorageUtils.getUser()

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInUp'
  //      'rag-fadeInDown'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'
  //      'rag-fadeInUpBig'
  //      'rag-fadeInDownBig'
  //      'rag-fadeInRightBig'
  //      'rag-fadeInLeftBig'
  //      'rag-zoomBackDown'
  const animationName = "rag-fadeIn"

  if (location.pathname === "/login") {
    return (
      <BasePage>
        <Switch location={location}>
          <Route path={"/login"} component={Login} />
        </Switch>
      </BasePage>
    )
  } else if (location.pathname === "/password-reset") {
    return (
      <BasePage>
        <Switch location={location}>
          <Route path={"/password-reset"} component={PasswordReset} />
        </Switch>
      </BasePage>
    )
  } else if (!user) {
    return <Redirect to={`/login?redirectback=${window.location.href}`} />
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Switch location={location}>
                <Route path="/welcome" component={Welcome} />
                {/*Dashboard*/}
                <Route
                  path="/dashboards/financial"
                  component={DashboardFinancial}
                />
                <Route path="/dashboards/wallet" component={DashboardWallet} />
                <Route
                  path="/dashboards/metrics"
                  component={DashboardMetrics}
                />
                <Route
                  path="/dashboards/support"
                  component={DashboardSupport}
                />
                <Route
                  path="/dashboards/partners"
                  component={DashboardPartners}
                />
                <Route
                  path="/dashboards/treasury"
                  component={DashboardTreasury}
                />
                <Route
                  path="/dashboards/compliance"
                  component={DashboardCompliance}
                />
                <Route path="/dashboards/statistics" component={Statistics} />
                <Route
                  path="/dashboards/demographics"
                  component={Demographics}
                />
                <Route
                  path="/dashboards/mobile-app"
                  component={MobileAppDashboard}
                />
                <Route
                  path="/dashboards/business"
                  component={BusinessDashboard}
                />
                <Route path="/dashboards/accounting" component={Accounting} />
                <Route path="/dashboards/mobile" component={Mobile} />

                {/*Orders*/}
                <Route path="/orders/buy" exact component={BuyOrders} />
                <Route path="/orders/buy/:id" exact component={BuyOrder} />
                <Route path="/orders/sell" exact component={SellOrders} />
                <Route path="/orders/sell/:id" exact component={SellOrder} />
                <Route
                  path="/orders/all"
                  exact
                  component={props => (
                    <TransactionList {...props} type="AGGREGATED" />
                  )}
                />
                <Route
                  path="/orders/payout"
                  exact
                  component={props => <PayoutList {...props} />}
                />
                <Route
                  path="/orders/limits"
                  exact
                  component={props => <OrderLimits {...props} />}
                />
                {/* Wallet */}
                <Route
                  path="/wallet/incoming"
                  exact
                  component={props => (
                    <TransactionList {...props} type="DEPOSIT" />
                  )}
                />
                <Route
                  path="/wallet/outgoing"
                  exact
                  component={props => (
                    <TransactionList {...props} type="WITHDRAWAL" />
                  )}
                />
                <Route
                  path="/wallet/swaps"
                  exact
                  component={SwapTransactionList}
                />
                <Route
                  path="/wallet/transaction/:id"
                  exact
                  component={TransactionDetail}
                />
                <Route
                  path="/wallet/transaction/swap/:id"
                  exact
                  component={TransactionDetailSwap}
                />

                {/*Customers */}
                <Route path="/customers" exact component={Customers} />
                <Route path="/customers/:id" exact component={Customer} />

                {/*Comments*/}
                <Route path="/comment/search" exact component={CommentSearch} />

                {/*Review*/}
                <Route path="/review" exact component={CustomerReview} />

                {/*Partners*/}
                <Route path="/partners" exact component={Partners} />
                <Route path="/partners/partner/:id" exact component={Partner} />

                {/*Transactions */}
                <Route
                  path="/transactions/paymentnotifications"
                  exact
                  component={PaymentNotifications}
                />
                <Route
                  path="/transactions/paymentnotifications/:id"
                  exact
                  component={MatchUnmatched}
                />
                {/*Operations */}
                <Route
                  path="/operations/coin-functions"
                  exact
                  component={CoinFunctions}
                />
                {/*Admin */}
                <Route path="/admin/users" exact component={AdminUsers} />
                <Route
                  path="/admin/permissions"
                  exact
                  component={AdminPermissions}
                />
                <Route path="/admin/adduser" exact component={AddUser} />
                <Route path="/admin/users/:id" exact component={User} />

                {/*Developer */}
                <Route
                  path="/developer/feature-flag"
                  exact
                  component={FeatureFlag}
                />
                <Route
                  path="/developer/customer-takeover"
                  exact
                  component={CustomerTakeover}
                />
                {/* Whistleblower */}
                <Route path="/whistleblower" component={Whistleblower} />

                {/* Tools */}
                <Route path="/tools" component={Tools} />

                <Redirect to="/login" />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
        <ToastContainer autoClose={3000} hideProgressBar={true} />
      </Base>
    )
  }
}

export default withRouter(Routes)
