import React, { Component } from "react"
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import CustomDropdownItem from "../Common/CustomDropdownItem"

class TradeModal extends Component {
  state = {
    provider: "bitstamp",
    providerLabel: "Bitstamp",
    dropdownProvider: false,
    loaded: false,
  }

  static getDerivedStateFromProps(props, current_state) {
    if (
      !current_state.loaded &&
      props.order &&
      props.order.cryptoCurrency === "BTC"
    ) {
      return {
        loaded: true,
        provider: "bitstamp",
        providerLabel: "Bitstamp",
      }
    }
    return null
  }

  toggleDropdown = () => {
    this.setState({
      dropdownProvider: !this.state.dropdownProvider,
    })
  }

  changeProvider = (value, label) => {
    this.setState({
      provider: value,
      providerLabel: label,
    })
  }

  onToggle = () => {
    if (this.props.onToggle) {
      this.props.onToggle()
    }
  }

  onTrade = () => {
    if (this.props.onTrade) {
      this.props.onTrade(this.state.provider)
    }
  }

  getSupportedProviders = () => {
    let options = []
    options.push(
      <CustomDropdownItem
        key="1"
        value="bitstamp"
        onSelect={this.changeProvider}
      >
        Bitstamp
      </CustomDropdownItem>,
    )
    options.push(
      <CustomDropdownItem
        key="2"
        value="talos"
        onSelect={this.changeProvider}
      >
        Talos
      </CustomDropdownItem>,
    )

    if (this.props.order.cryptoCurrency === "BTC") {
      options.push(
        <CustomDropdownItem
          key="3"
          value="blockchain"
          onSelect={this.changeProvider}
        >
          Blockchain
        </CustomDropdownItem>,
      )
    } else if (this.props.order.cryptoCurrency === "DOT") {
      // DOT only supports Blockchain
      options = []
      options.push(
        <CustomDropdownItem
          key="3"
          value="blockchain"
          onSelect={this.changeProvider}
        >
          Blockchain
        </CustomDropdownItem>,
      )
    }

    return options
  }

  render() {
    if (!this.props.order) return ""

    return (
      <Modal isOpen={this.props.open} toggle={this.onToggle}>
        <ModalHeader toggle={this.onToggle}>Trade order</ModalHeader>
        <ModalBody>
          <div>
            <p>Provider</p>
            <Dropdown
              isOpen={this.state.dropdownProvider}
              toggle={this.toggleDropdown}
            >
              <DropdownToggle color="secondary" caret>
                {this.state.providerLabel}
              </DropdownToggle>
              <DropdownMenu>{this.getSupportedProviders()}</DropdownMenu>
            </Dropdown>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onTrade}>
            Trade
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default TradeModal
